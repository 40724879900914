<template>
  <div>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="toggleModal"
    >
      <feather-icon
        size="16"
        icon="ShieldIcon"
        class="mr-50"
      />
      {{ buttonText }}
    </b-dropdown-item>
    <b-modal
      v-model="isModalOpened"
      centered
      hide-footer
    >
      <EnableTwofaModalContent
        v-if="!admin.isTwoFactorAuthenticationEnabled"
        @close-modal="closeModal"
      />
      <DisableTwofaModalContent
        v-else
        @close-modal="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { BDropdownItem, BModal } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'
import EnableTwofaModalContent from '@/views/apps/create-admin/components/EnableTwofaModalContent.vue'
import DisableTwofaModalContent from '@/views/apps/create-admin/components/DisableTwofaModalContent.vue'

export default {
  name: 'ChangeAdminTwofa',
  components: {
    BDropdownItem,
    DisableTwofaModalContent,
    EnableTwofaModalContent,
    BModal,
  },

  data() {
    return {
      isModalOpened: false,
    }
  },

  computed: {
    ...mapGetters({
      twofa: 'auth/twofaCode',
      admin: 'auth/admin',
    }),
    buttonText() {
      return this.admin.isTwoFactorAuthenticationEnabled ? 'Disable 2fa' : 'Enable 2fa'
    },
  },

  methods: {
    ...mapActions({
      generateTwofa: 'auth/generateTwofaCode',
    }),
    toggleModal() {
      this.isModalOpened = !this.isModalOpened
    },

    closeModal() {
      this.isModalOpened = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
