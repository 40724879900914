import { mapGetters } from 'vuex'
import countersKey from '@/config/navigation/vertical/countersKey'

// mixin for side menu - view and adding counters
export default {
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
      pendingCounter: 'userInvites/pendingCounter',
      failedCounter: 'transactions/failedCounter',
      buySellFailedCounter: 'pgTransactions/buySellFailedCounter',
    }),

    navItems() {
      const filterNavByPermission = this.filterNavByPermission(this.navMenuItems)
      const navigation = this.navigationWithCounters(filterNavByPermission)

      return navigation
    },
  },

  methods: {
    filterNavByPermission(nav) {
      return nav
        .map(navigationItem => {
          const hasPermission = this.hasPermission(navigationItem.permission)

          if (hasPermission && navigationItem.header) {
            return navigationItem
          }

          if (hasPermission && navigationItem.children) {
            const children = navigationItem.children.filter(item => !this.checkPermission(item))
            return { ...navigationItem, children }
          }

          return hasPermission && navigationItem
        })
        .filter(Boolean)
    },

    navigationWithCounters(nav) {
      // add menu counters if needed
      return nav?.map(item => {
        let menuItem = { ...item }

        if (item.key === countersKey.userInvites) {
          menuItem = { ...item, tag: this.pendingCounter }
        }

        if (item.key === countersKey.swapTransactions) {
          menuItem = { ...item, tag: this.failedCounter }
        }

        if (item.key === countersKey.buySell) {
          menuItem = { ...item, tag: this.buySellFailedCounter }
        }

        return menuItem
      })
    },

    checkPermission(item) {
      return this.listOwnerPermissions?.includes(item)
    },

    hasPermission(permission) {
      if (Array.isArray(permission)) {
        return permission?.some(this.checkPermission)
      }

      return this.checkPermission(permission)
    },
  },
}
