import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'

const { t } = useI18nUtils()
const { canViewVerticalNavMenuHeader } = useAclUtils()
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    const span = h('span', {}, t(this.item.header))
    const icon = h('feather-icon', { props: { icon: 'MoreHorizontalIcon', size: '18' } })
    const tag = this.item.tag
      ? h(
          BBadge, 
          {
            class: 'mr-1 ml-auto',
            props: {
              pill: true,
              variant: this.item.tagVariant || 'primary',
            },
          },
          [this.item?.tag], // children
        )
      : null

    if (canViewVerticalNavMenuHeader(this.item)) {
      return h('li', { class: 'navigation-header text-truncate' }, [span, icon, ' ', tag])
    }
    return h()
  },
}
