<template>
  <b-card>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Disable Google Two Factor Authentication
    </b-card-title>
    <validation-observer ref="twofaValidation">
      <b-form
        class="my-2"
        @submit.prevent
      >
        <validation-provider
          #default="{ errors }"
          name="twofa"
          rules="required|digits:6"
        >
          <b-form-group
            label="Google 2FA code"
            label-for="twofa"
          >
            <b-form-input
              id="twofa"
              v-model="twofaCode"
              placeholder="Google authenticator code"
              name="twofa"
              :state="errors.length ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="requestInProgress"
          class="mt-1"
          @click="validationForm"
        >
          Disable 2FA
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardTitle, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, digits } from '@validations'

export default {
  name: 'DisableTwofaModalContent',
  components: {
    ValidationObserver,
    BCardTitle,
    BCard,
    BFormGroup,
    ValidationProvider,
    BForm,
    BButton,
    BFormInput,
  },
  mixins: [waitRequest, validationError],
  emits: ['close-modal'],
  data() {
    return {
      twofaCode: '',
    }
  },
  mounted() {
    this.registerValidator(this.$refs.twofaValidation)
  },
  methods: {
    ...mapActions({
      turnOffTwofa: 'auth/turnOffTwofa',
      fetchProfile: 'auth/fetchProfile',
    }),
    async validationForm() {
      if (this.requestInProgress) return

      const isValid = await this.checkIsValidForm()
      if (!isValid) return

      return this.waitRequest(() => this.turnOffTwofa({
        code: this.twofaCode,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '2fa is disabled',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
          this.$emit('close-modal')
          this.fetchProfile()
        })
        .catch(this.checkErrors))
    },
  },
}
</script>

<style scoped lang="scss"></style>
