<template>
  <b-card>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Enable Google Two Factor Authentication
    </b-card-title>

    <b-card-text class="mb-2">
      1) Download Google Authenticator
      <a
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener noreferrer nofollow"
        class="link"
      >
        Android
      </a>
      <span class="color-blue">/</span>
      <a
        href="https://apps.apple.com/app/google-authenticator/id388497605"
        target="_blank"
        rel="noopener noreferrer nofollow"
        class="link"
      >
        IOs
      </a>
    </b-card-text>

    <b-card-text class="mb-2">
      2) Add key phrase into Google Authenticator and remember the key phrase
    </b-card-text>
    <b-card-text class="font-small-2">
      Open Google Authenticator, scan the QR code below or manually enter the key phrase to activate the verification
      token.
    </b-card-text>
    <b-card-text class="font-small-2">
      Key phrase is used to recover Google Authenticator in the event of a loss or change of device — please make sure
      to keep the key phrase safe before setting up Google Authenticator.
    </b-card-text>
    <div class="d-flex align-items-center">
      <vue-qrcode
        v-if="otpUrl"
        class="mt-2 mx-auto"
        :value="otpUrl"
      />
    </div>
    <div class="d-flex">
      <b-form-input
        readonly
        :value="token"
        class="text-white mt-2"
      />
      <b-button
        variant="primary"
        class="mt-2"
        @click="doCopy(token)"
      >
        <feather-icon
          icon="CopyIcon"
          size="12"
        />
      </b-button>
    </div>
    <validation-observer ref="twofaValidation">
      <b-form
        class="my-2"
        @submit.prevent
      >
        <validation-provider
          #default="{ errors }"
          name="twofa"
          rules="required|digits:6"
        >
          <b-form-group
            label="Google 2FA code"
            label-for="twofa"
          >
            <b-form-input
              v-model="twofaCode"
              placeholder="Google authenticator code"
              name="twofa"
              :state="errors.length ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="requestInProgress"
          class="mt-1"
          @click="validationForm"
        >
          Enable 2FA
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {
  BButton, BCard, BCardText, BCardTitle, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import {
  ValidationObserver, ValidationProvider,
} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import waitRequest from '@/mixins/waitRequest'
import doCopy from '@/mixins/doCopy'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, digits} from '@validations'

export default {
  name: 'EnableTwofaModal',
  components: {
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BForm,
    BButton,
    BFormInput,
    BCardText,
    BCardTitle,
    BCard,
    VueQrcode,
  },
  mixins: [waitRequest, validationError, doCopy],
  emits: ['close-modal'],
  data() {
    return {
      token: '',
      otpUrl: '',
      twofaCode: '',
    }
  },

  mounted() {
    this.registerValidator(this.$refs.twofaValidation)
    this.waitRequest(() => this.generateTwofaCode().then(res => {
      this.token = res.data.token
      this.otpUrl = res.data.otpauthUrl
    })).catch(this.checkErrors)
  },
  methods: {
    ...mapActions({
      generateTwofaCode: 'auth/generateTwofaCode',
      turnOnTwofa: 'auth/turnOnTwofa',
      fetchProfile: 'auth/fetchProfile',
    }),

    async validationForm() {
      if (this.requestInProgress) return
      const isValid = await this.checkIsValidForm()
      if (!isValid) return

      return this.turnOnTwofa({
        code: this.twofaCode,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '2fa is enabled',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
          this.$emit('close-modal')
          this.fetchProfile()
        })
        .catch(this.checkErrors)
    },
  },
}
</script>

<style scoped lang="scss"></style>
